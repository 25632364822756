<div class="container-fluid px-md-4">
  <div class="row py-4 px-md-5 ">
    <div class="col-12 col-md-10 ">
      <h1 class="burgendy fw-semibold">About Us</h1>
    </div>
    <div class="col-12 col-md-12 ">
      <div class="row">
        <div class="col-12  col-xl-6">
          <img class="img-fluid " [src]="'../../../assets/images/coPresidents.png'" alt="Co-president image">
        </div>
        <div class="col-12 col-xl-6">
          <div>
            <h3 class="burgendy fw-bold pt-md-3">Welcome to the HBS Club of Seattle!</h3>
            <p><b>Dear HBS Seattle Alumni Community,</b> <br>
              As Co-Presidents of the HBS Club of Seattle,
              we are thrilled to welcome you to an exciting new chapter in 2025. The HBS alumni network is a powerful
              community of leaders, innovators, and changemakers, and it is our privilege to serve and strengthen this
              vibrant group in the greater Seattle area.
              Over the past year, we’ve witnessed incredible growth and engagement, underscoring the resilience and
              commitment of our alumni. As we look to the year ahead, our mission remains focused on cultivating
              meaningful connections, fostering lifelong learning, and driving positive impact in our local community.
            </p>

          </div>
        </div>
      </div>

      <!-- our vision section -->
      <div class="col-12 pt-2 p-0 pt-md-5">
        <div class="row">
          <div class="col-12 col-xl-6">
            <h3 class="burgendy fw-semibold pt-2">Our Vision for 2025</h3>
            <p class="pt-1"> This year, we aim to build on the strong foundation established by past leaders while
              introducing new initiatives to take the club to greater heights. Our priorities include:</p>
            <ol type="1">
              <li><b>Strengthening Alumni Connections:</b> Expanding our reach to engage 1,000+ alumni in both social
                and professional settings. </li>
              <li><b> Championing Entrepreneurship:</b> Hosting events and mentorship programs to support startups and
                inspire innovative thinking.</li>
              <li><b>Promoting Executive Education:</b> Providing tailored programs to support alumni at critical
                transition points in their careers.</li>
              <li><b>Collaborating Across Communities:</b> Partnering with Harvard’s global network and other esteemed
                institutions to bring fresh perspectives to Seattle.</li>
              <li><b>Ensuring Financial Sustainability:</b>Continuing to operate as a financially strong organization,
                enabling us to deliver exceptional events and initiatives for our members.</li>
            </ol>
          </div>
          <div class="col-12 col-xl-6 ">
            <img class="img-fluid" [src]="'../../../assets/images/our_vision.png'" alt="president image">
          </div>
        </div>
      </div>


      <!-- New Store section -->
      <div class="col-12 col-md-12 pt-2 p-0  pt-md-5">
        <div class="row">

          <div class="col-12 col-xl-6">
            <img class="img-fluid" [src]="'../../../assets/images/Store.png'" alt="public image">
          </div>
          <div class="col-12 col-xl-6 pt-2">
            <h3 class="burgendy fw-semibold">What’s in Store for 2025</h3>
            <p>We are excited to host a variety of signature events this year, including:</p>
            <ul>
              <li>Engaging discussions with corporate leaders, professors, and community influencers.</li>
              <li>Networking opportunities designed to foster collaboration and mentorship.</li>
              <li>Unique social gatherings that showcase the best of Seattle.</li>
            </ul>
            <p>We are also introducing new governance frameworks, including updated bylaws and a code of conduct, to
              ensure the club operates with excellence and inclusivity.</p>
            <!-- <span class="col-12">Co-Presidents, HBS Club of Seattle</span> -->
          </div>

        </div>
      </div>

      <!-- New Role section -->
      <div class="col-12 col-md-12 pt-2 p-0  pt-md-5 ">
        <div class="row">
          <div class="col-12 col-xl-6">
            <h3 class="burgendy fw-semibold pt-2">Your Role in Our Journey</h3>
            <p>The success of the HBS Club of Seattle depends on the active participation and contributions of our
              alumni. We invite you to join us at our events, share your ideas, and explore ways to get involved—whether
              through volunteering, sponsorship, or simply reconnecting with your fellow alumni. <br />

              Together, we can make 2025 a transformative year for our club and community. Please feel free to reach out
              to either of us with your thoughts, suggestions, or questions. We are here to listen and collaborate.
              <br />
            </p>
            Warm regards,<br />
            <b>Jagan Chitiprolu & Sonam Saxena</b><br />
            Co-Presidents, HBS Club of Seattle

          </div>
          <div class="col-12 col-xl-6 pt-md-2">
            <img class="img-fluid" [src]="'../../../assets/images/Role.png'" alt="president image">
          </div>
        </div>
      </div>

      <!-- our mission section -->
      <div class="col-12 col-md-12 pt-2  p-0 pt-md-5 ">
        <div class="row">
          <div class="col-12 col-xl-6 col-md-6 col-md-12">
            <img class="img-fluid" [src]="'../../../assets/images/Mission.png'" alt="president image">
          </div>
          <div class="col-12 col-xl-6 pt-2 ">
            <h3 class="burgendy fw-semibold">Our Mission for 2025</h3>
            <p class="pt-1"> To cultivate a dynamic and inclusive community of Harvard Business School alumni in the
              greater Seattle area, empowering leaders to make a lasting impact on their organizations, industries, and
              society.
            </p>
            <b> We strive to achieve this mission by:</b>
            <ol type="1">
              <li><b>Fostering Meaningful Connections:</b>
                Building a vibrant network that enables personal and professional growth through social, professional,
                and collaborative opportunities. </li>
              <li><b> Promoting Lifelong Learning:</b>
                Extending the HBS tradition of excellence by organizing events and programs featuring thought leaders,
                industry pioneers, and innovative thinkers.</li>
              <li><b>Promoting Executive Education:</b>
                Providing tailored programs to support alumni at critical transition points in their careers.</li>
              <li><b>Inspiring Leadership and Service:</b>
                Encouraging alumni to give back to the local community through mentorship, volunteering, and outreach
                initiatives that drive positive change.</li>
              <li><b>Strengthening Global Ties:</b>Partnering with HBS alumni clubs worldwide to create opportunities
                for cross-regional collaboration and shared learning.
              </li>
            </ol>
            <p>At the HBS Club of Seattle, we are dedicated to advancing the values of Harvard Business School—educating
              leaders who make a difference in the world—and creating a community that supports and inspires our members
              to thrive and contribute meaningfully.</p>
          </div>

        </div>
      </div>

      <!--  club section-->
      <div class="col-12 col-md-12  d-flex justify-content-center pt-2  p-0 pt-md-5  ">
        <h3 class="burgendy fw-semibold  ">Club Officers & Board of Directors </h3>
      </div>
      <div class="col-12 col-md-12 pt-2   p-0 pt-md-5 d-flex align-items-center py-5">
        <div class="row justify-content-center">

          <div class="col-12 col-md-3 me-md-4 pb-2 pb-md-0">
            <div class="card">
              <img [src]="'../../../assets/images/jon.jpg'" class="img-rounded img-fluid w-100 p-2 pt-3 cardImage"
                alt="Co-President image">
              <div class="text-center pt-3 pb-4 color-ash">
                <h6 class="fw-semibold m-0 aboutus-color">Jon Weintraub</h6>
                <small class="fw-semibold subTitle m-0">Chairman</small>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-3 me-md-4 pb-2 pb-md-0">
            <div class="card">
              <img [src]="'../../../assets/images/Jagan Chitiprolu.jpg'"
                class="img-rounded img-fluid w-100 p-2 pt-3 cardImage" alt="Board Member image">
              <div class="text-center pt-3 pb-4 color-ash">
                <h6 class="fw-semibold m-0 aboutus-color">Jagan Chitiprolu</h6>
                <small class="fw-semibold subTitle m-0">Co-President</small>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-3 me-md-4">
            <div class="card">
              <img [src]="'../../../assets/images/Sonam.jpg'" class="img-rounded img-fluid w-100 p-2 pt-3 cardImage"
                alt="Board Member image">
              <div class="text-center pt-3 pb-4 color-ash">
                <h6 class="fw-semibold m-0 aboutus-color">Sonam Saxena</h6>
                <small class="fw-semibold subTitle m-0">Co-President</small>
              </div>
            </div>
          </div>

        </div>

      </div>

      <hr />
      <!-- board member section -->
      <div class="col-12 col-md-12  d-flex justify-content-center pt-2  p-0 pt-md-3   ">
        <h3 class="burgendy fw-semibold  ">Board Members</h3>
      </div>
      <div class="col-12 col-md-12 pt-2 p-0 pt-md-0 d-flex align-items-center py-5">
        <div class="row justify-content-center px-md-2">

          <div class="col-12 col-md-3 d-flex justify-content-center pt-md-3 pb-2 pb-md-0">
            <div class="card">
              <img [src]="'../../../assets/images/alex.jpg'" class="img-rounded img-fluid w-100 p-2 pt-3 cardImage"
                alt="Board Member image">
              <div class="text-center pt-3 pb-4 color-ash">
                <h6 class="fw-semibold m-0 aboutus-color">Alex Ray</h6>
                <small class="fw-semibold subTitle m-0">Board Member</small>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-3 d-flex justify-content-center pt-md-3 pb-2 pb-md-0">
            <div class="card">
              <img [src]="'../../../assets/images/kyle.jpg'" class="img-rounded img-fluid w-100 p-2 pt-3 cardImage"
                alt="Board Member image">
              <div class="text-center pt-3 pb-4 color-ash">
                <h6 class="fw-semibold m-0 aboutus-color">Kyle Buffenmyer</h6>
                <small class="fw-semibold subTitle m-0">Board Member</small>
              </div>
            </div>
          </div>


          <div class="col-12 col-md-3 d-flex justify-content-center pt-md-3 pb-2 pb-md-0">
            <div class="card">
              <img [src]="'../../../assets/images/Yin.jpg'" class="img-rounded img-fluid w-100 p-2 pt-3 cardImage"
                alt="Board Member image">
              <div class="text-center pt-3 pb-4 color-ash">
                <h6 class="fw-semibold m-0 aboutus-color">Yin Wang</h6>
                <small class="fw-semibold subTitle m-0">Board Member</small>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-3 d-flex justify-content-center pt-md-3 pb-2 pb-md-0">
            <div class="card w-100">
              <img [src]="'../../../assets/images/Tom.jpg'" class="img-rounded img-fluid w-100 p-2 pt-3 cardImage"
                alt="Board Member image">
              <div class="text-center pt-3 pb-4 color-ash">
                <h6 class="fw-semibold m-0 aboutus-color">Tom Lapham</h6>
                <small class="fw-semibold subTitle m-0">Board Member</small>
              </div>
            </div>
          </div>


          <div class="col-12 col-md-3 d-flex justify-content-center pt-md-3">
            <div class="card">
              <img [src]="'../../../assets/images/shyam.jpg'" class="img-rounded img-fluid w-100 p-2 pt-3 cardImage"
                alt="Board Member image">
              <div class="text-center pt-3 pb-4 color-ash">
                <h6 class="fw-semibold m-0 aboutus-color">Shyam Ayengar</h6>
                <small class="fw-semibold subTitle m-0">Board Member</small>
              </div>
            </div>
          </div>

        </div>
      </div>




      <hr />

      <!-- board member alternativ esection -->
      <div class="col-12 col-md-12 pt-2 p-0 pt-md-2 d-flex align-items-center py-5">
        <div class="row justify-content-center px-md-2">
          <!-- me-md-5 -->
          <div class="col-12 col-xl-5 col-md-6  d-flex flex-column align-items-center">
            <h3 class="burgendy fw-semibold col-12 text-center pb-md-3">Board Alternatives</h3>
            <div class="card col-md-9  col-lg-7 col-12 ">
              <img [src]="'../../../assets/images/Amita.jpg'" class="img-rounded img-fluid w-100 p-2 pt-3 cardImage"
                alt="Board Member image">
              <div class="text-center pt-3 pb-4 color-ash">
                <h6 class="fw-semibold m-0 aboutus-color">Amita Gudipati</h6>
                <small class="fw-semibold subTitle m-0">Board Member</small>
              </div>
            </div>
          </div>
          <!-- me-md-5  -->
          <div class="col-12 col-xl-5 col-md-6 d-flex flex-column align-items-center">
            <h2 class="burgendy fw-semibold text-center col-12 pt-md-0 pt-2 pb-md-3">Technology Committee</h2>
            <div class="card col-md-9 col-lg-7 col-12 pt-2">
              <img [src]="'../../../assets/images/Sonam.jpg'" class="img-rounded img-fluid w-100 p-2 pt-3 cardImage"
                alt="Technology Committee image">
              <div class="text-center pt-3 pb-4 color-ash">
                <h6 class="fw-semibold m-0 aboutus-color">Sonam Saxena</h6>
                <small class="fw-semibold subTitle m-0">Technology Committee</small>
              </div>
            </div>
          </div>

        </div>
      </div>


      <hr />

      <div class="col-12 col-md-12 pt-2 p-0 pt-md-2 d-flex align-items-center py-md-3">
        <div class="row  px-md-2">
          <div class="col-12 col-lg-6  d-flex flex-column align-items-center">
            <h3 class="burgendy fw-semibold col-12 text-center pb-md-3">Fundraising Committee</h3>

            <div class="row justify-content-center w-100">
              <div class="col-12 col-md-6 mb-4">
                <div class="card w-100">
                  <img [src]="'../../../assets/images/Tom.jpg'"
                    class="img-rounded img-fluid w-100 p-2 pt-3 cardImage" alt="Fundraising Committee image">
                  <div class="text-center pt-3 pb-4 color-ash">
                    <h6 class="fw-semibold m-0 aboutus-color">Tom Lapham</h6>
                    <small class="fw-semibold subTitle m-0">Fundraising Committee</small>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mb-4">
                <div class="card w-100">
                  <img [src]="'../../../assets/images/Jagan Chitiprolu.jpg'"
                    class="img-rounded img-fluid w-100 p-2 pt-3 cardImage" alt="Fundraising Committee image">
                  <div class="text-center pt-3 pb-4 color-ash">
                    <h6 class="fw-semibold m-0 aboutus-color">Jagan Chitiprolu</h6>
                    <small class="fw-semibold subTitle m-0">Fundraising Committee</small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-6 d-flex flex-column align-items-center">
            <h3 class="burgendy fw-semibold text-center pb-md-3">Leadership Outreach</h3>

            <div class="row justify-content-center w-100">

              <div class="col-12 col-md-6 mb-4">
                <div class="card w-100">
                  <img [src]="'../../../assets/images/jon.jpg'"
                    class="img-rounded img-fluid w-100 p-2 pt-3 cardImage" alt="Leadership Outreach image">
                  <div class="text-center pt-3 pb-4 color-ash">
                    <h6 class="fw-semibold m-0 aboutus-color">Jon Weintraub </h6>
                    <small class="fw-semibold subTitle m-0">Leadership Outreach</small>
                  </div>
                </div>
              </div> 
              <div class="col-12 col-md-6 mb-4">
                <div class="card w-100">
                  <img [src]="'../../../assets/images/Tom.jpg'"
                    class="img-rounded img-fluid w-100 p-2 pt-3 cardImage" alt="Leadership Outreach image">
                  <div class="text-center pt-3 pb-4 color-ash">
                    <h6 class="fw-semibold m-0 aboutus-color">Tom Lapham</h6>
                    <small class="fw-semibold subTitle m-0">Leadership Outreach</small>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <hr>
      <!-- board member alternative2 esection -->
      <div class="col-12 col-md-12 pt-2 p-0 pt-md-2 d-flex align-items-center py-5">
        <div class="row justify-content-center px-md-2">
          <div class="col-12 col-xl-6   d-flex flex-column align-items-center">
            <h3 class="burgendy fw-semibold col-12 text-center pb-md-2 pb-xl-3">Promoting Executive Education</h3>

            <div class="row justify-content-center w-100">
              <div class="col-12 col-md-6 mb-4">
                <div class="card w-100">
                  <img [src]="'../../../assets/images/Amita.jpg'" class="img-rounded img-fluid w-100 p-2 pt-3 cardImage"
                    alt="Promoting Executive image">
                  <div class="text-center pt-3 pb-4 color-ash">
                    <h6 class="fw-semibold m-0 aboutus-color">Amita Gudipati</h6>
                    <small class="fw-semibold subTitle m-0">Promoting Executive Education</small>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mb-4">
                <div class="card w-100">
                  <img [src]="'../../../assets/images/Jagan Chitiprolu.jpg'"
                    class="img-rounded img-fluid w-100 p-2 pt-3 cardImage" alt="Co-President image">
                  <div class="text-center pt-3 pb-4 color-ash">
                    <h6 class="fw-semibold m-0 aboutus-color">Jagan Chitiprolu</h6>
                    <small class="fw-semibold subTitle m-0">Promoting Executive Education</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-6 d-flex flex-column align-items-center">
            <h3 class="burgendy fw-semibold text-center pb-md-2 pb-xl-3">Entrepreneurship Committee</h3>

            <div class="row justify-content-center w-100">
              <div class="col-12 col-md-6 mb-4">
                <div class="card w-100">
                  <img [src]="'../../../assets/images/alex.jpg'" class="img-rounded img-fluid w-100 p-2 pt-3 cardImage"
                    alt="Promoting Executive image">
                  <div class="text-center pt-3 pb-4 color-ash">
                    <h6 class="fw-semibold m-0 aboutus-color">Alex Ray</h6>
                    <small class="fw-semibold subTitle m-0">Entrepreneurship Committee</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>