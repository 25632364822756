<div class="container-fluid default-font-size">
  <div class="row">
    <div class="col-12 p-0 position-relative">
      <img src="assets/images/homeBackgroundImage.jpg" alt="Banner Image" class="img-fluid w-100" />
      <div class="position-absolute top-50 start-50 translate-middle text-white" *ngIf="showRegister">
        <h1 class="fw-bolder home-title">HBS Club of Seattle</h1>
        <p class="pt-1 home-title-description">Welcome to the official website of the Harvard Business School Club of Seattle.</p>
        <div class="banner_btn">
          <p-button label="Read More" icon="pi pi-arrow-right" iconPos="right" class="maroon_btn pt-3"
        (click)="navigateToaboutus()"></p-button>
        </div>
      </div>
    </div>
  </div>  
  <div class="row px-md-5">
    <div class="col-12 col-md-10 offset-md-1 py-4">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="grid">
              <div class="px-1 pt-1">
                <img src="assets/images/aboutus_image1.png" alt="" class="img-fluid"/>
              </div>
              <div class="px-1 pt-4">
                <img src="assets/images/aboutus_image2.png" alt="" class="img-fluid"/>
              </div>
              <div class="pr-1">
                <img src="assets/images/aboutus_image3.png" alt="" class="img-fluid"/>
              </div>
              <div class="pt-4 px-2">
                <img src="assets/images/aboutus_image4.png" alt="" class="img-fluid"/>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="row">
              <div class="col-12 offset-md-1 d-flex align-items-center m-0">
                <i class="pi pi-minus burgendy p-3 fw-semibold"></i>
                <h4 class="burgendy fw-semibold">About Us</h4>
              </div>
              <div class="col-12 offset-md-1">
                <h1 class="fw-semibold text-dark">Our Mission</h1>
                <p class="pt-3">  To cultivate a dynamic and inclusive community of Harvard Business School alumni in the greater Seattle area, empowering leaders to make a lasting impact on their organizations, industries, and society. 
                  <br/><b> We strive to achieve this mission by:</b></p>
                  <b><ol type="1" >
                <li><b>Fostering Meaningful Connections</b>
                   <!-- Building a vibrant network that enables personal and professional growth
                   through social, professional, and collaborative opportunities.  --></li> 
               <li> Promoting Lifelong Learning
                  <!-- Extending the HBS tradition of excellence by organizing events and programs 
                  featuring thought leaders, industry pioneers, and innovative thinkers. -->
                </li>
                <li><b>Inspiring Leadership and Service</b> 
                  <!-- Encouraging alumni to give back to the local community 
                  through mentorship, volunteering, and outreach initiatives that drive positive change. -->
                </li>
                <li><b>Strengthening Global Ties</b> 
                  <!-- Partnering with HBS alumni clubs worldwide to create
                   opportunities for cross-regional collaboration and shared learning. -->
                  </li>
              </ol></b>
              <p>At the HBS Club of Seattle, we are dedicated to advancing the values of Harvard Business School—educating leaders who make a difference in the world—and creating a community that supports and inspires our members to thrive and contribute meaningfully.</p>
              </div>
            </div>
            <div class="banner_btn pl-3 ml-4 pt-0 mt-0">
              <p-button label="Read More" icon="pi pi-arrow-right" iconPos="right" class="maroon_btn pt-3"
            (click)="navigateToaboutus()"></p-button>
            </div>
          </div>
        </div>
       
    </div>
    <div class="col-12 col-md-10 offset-md-1">
      <hr>
    </div>
    <div class="col-12 col-md-10 offset-md-1 py-4">
      <div class="row" *ngIf="!summaryLoader">
        <div class="col-12 col-md-3 text-center h-100">
          <div class="shadow p-2 py-4 my-2 bg-light">
            <i class="pi pi-user-plus burgendy fs-4"></i>
            <h1>{{summaryData?.totalUsersCount}}</h1>
            <p>Alumni Enrolled</p>
          </div>
        </div>
        <div class="col-12 col-md-3 text-center h-100">
          <div class="shadow p-2 py-4 my-2 bg-light">
            <i class="pi pi-calendar burgendy fs-4"></i>
            <h1>{{summaryData?.totalEventsCount}}</h1>
            <p>Events</p>
          </div>
        </div>
        <div class="col-12 col-md-3 text-center h-100">
          <div class="shadow p-2 py-4 my-2 bg-light">
            <i class="pi pi-file burgendy fs-4"></i>
            <h1>{{summaryData?.totalBlogsCount}}</h1>
            <p>Blog Posts</p>
          </div>
        </div>
        <div class="col-12 col-md-3 text-center h-100">
          <div class="shadow p-2 py-4 my-2 bg-light">
            <i class="pi pi-calendar-plus burgendy fs-4"></i>
            <h1>{{summaryData?.totalNewsCount}}</h1>
            <p>News Articles</p>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="summaryLoader">
        <div class="col-12 text-center">
          <p-progressSpinner
          styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
          animationDuration=".5s"></p-progressSpinner>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="row bg-burgendy p-3 py-md-5 px-md-4">
    <div class="col-12" *ngIf="!eventsLoader">
      <div class="row">
        <div class="col-12 col-md-10 offset-md-1 d-flex align-items-center">
          <i class="pi pi-minus text-white p-3 fw-semibold"></i>
          <h4 class="text-white fw-semibold">Events</h4>
        </div>
        <div class="col-12 col-md-10 offset-md-1">
          <h1 class="text-white fw-semibold">Explore The Events Offered By HBS</h1>
        </div>
        <div class="col-12 col-md-10 offset-md-1 pt-3 ">
          <div class="row">
            <div class="col-12 col-md-4 my-2 " *ngFor="let event of eventsList">
              <div class="shadow  cursor-pointer p-2 bg-white my-2 h-100 bottom-line " (click)="navigateToEventDetails()">
                <div >
                  <div class="image-container position-relative">
                    <div class="image-text position-absolute  ">
                        <div class="text-content p-2 pl-4 bg-burgendy rounder_rightBottom__border text-white "> <b class="p-4 fs-6"> {{event?.eventCreatedDate | date: 'dd' }} </b> <br> <span class="p-3 fs-6"> {{event?.eventCreatedDate |date: 'MMM' }}</span></div>
                      </div>
                  <img alt="event" [src]="event?.images?.header" class=" Custom_image_height img-fluid" />
                </div>
                </div>
                <div>
                  <h4 class="fw-semibold pt-2 pl-3 custom_width__ForTextWrap text-truncate ">{{event?.name}}</h4>
                  <div class="d-flex align-items-center">
                    <i class="fa fa-clock-o p-3"> {{ event.eventStartDateAndTime | date:'shortTime'}}-{{ event.eventEndDateAndTime | date:'shortTime'}}</i>
                    <p class="m-0"></p>
                  </div>
                  <div class="d-flex align-items-center">
                    <i class="fa fa-map-marker p-3">{{event.timeZone}}</i>
                    <p class="m-0">{{event?.venue?.name}}</p>
                  </div>
                </div>
                <div >  
                  <p-editor class="pEditor__Readonly  text-wrap w-100" [(ngModel)]="event.description" [readonly]="true"></p-editor>
              </div> 
            </div>
            </div>
          </div>
        <div class="col-12 col-md-10 offset-md-1 pt-5 text-center">
          <p-button label="View All Events" icon="pi pi-arrow-right" iconPos="right" class="event__btn  p-0 px-4 py-2 font-weight-normal"
            (click)="navigateToEventDetails()"></p-button>
        </div>
      </div>
      </div>
    </div>
    <div class="col-12 text-center" *ngIf="eventsLoader">
      <p-progressSpinner
      styleClass="custom-spinner event_spinner" aria-label="Loading" strokeWidth="3"
      animationDuration=".5s"></p-progressSpinner>
    </div>
</div> -->

  <div class="row p-3 py-md-5 px-md-4">
    <div class="col-12" *ngIf="!blogsloader">
      <div class="row">
        <div class="col-12 col-md-10 offset-md-1 d-flex align-items-center justify-content-center">
          <i class="pi pi-minus p-3 fw-semibold burgendy"></i>
          <h5 class="fw-semibold burgendy">Blogs</h5>
          <i class="pi pi-minus p-3 fw-semibold burgendy"></i>
        </div>
        <div class="col-12 col-md-10 offset-md-1 d-flex align-items-center justify-content-center">
          <h1 class="fw-semibold">Checkout Our Blogs</h1>
        </div>
        <div class="col-12 col-md-10 offset-md-1 pt-3">
          <div class="row">
            <div class="col-12 col-md-4 my-2" *ngFor="let blog of blogList">
              <div class=" p-2 my-2 h-100 cursor-pointer "  (click)="navigateToBlogsScreen()" >
                <div class="image-container w-100 overflow-hidden">
                  <img src="{{blog?.image}}" alt="blog image" class="img-fluid bg-black img-fixed-size">
                </div>
                <div>
                  <h5 class="fw-semibold pt-2 pl-3 custom_width__ForTextWrap text-truncate ">{{blog?.title}}</h5>
                </div>
                <div>
                  <p-editor class="pEditor__Readonly  text-wrap w-100" [(ngModel)]="blog.description" [readonly]="true"></p-editor>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-10 offset-md-1 pt-5 text-center">
          <p-button label="View All Blogs" icon="pi pi-arrow-right" iconPos="right" class="maroon_btn"
            (click)="navigateToBlogsScreen()"></p-button>
        </div>
      </div>
    </div>
    <div class="col-12 text-center"  *ngIf="blogsloader">
      <p-progressSpinner
      styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
      animationDuration=".5s"></p-progressSpinner>
    </div>
  </div>
  <div class="row p-3 py-md-5 px-md-4 bg-light-ash news_list">
    <div class="col-12" *ngIf="!newsLoader">
      <div class="row">
        <div class="col-12 col-md-10 offset-md-1 d-flex align-items-center justify-content-center">
          <i class="pi pi-minus burgendy p-3 fw-semibold"></i>
          <h5 class="burgendy fw-semibold">News</h5>
          <i class="pi pi-minus burgendy p-3 fw-semibold"></i>
        </div>
        <div class="col-12 col-md-10 offset-md-1 text-center">
          <h1 class="text-dark fw-semibold">Checkout The Latest News</h1>
        </div>
        <div class="col-12 col-md-10 offset-md-1 pt-3">
          <div class="row">
            <div class="col-12 col-md-4 my-2" *ngFor="let news of newsList">
              <div class=" p-2 cursor-pointer my-2 h-100 "  (click)="navigateToNewsScreen()">
                <div>
                  <div class="image-container position-relative">
                    <!-- <div class="image-text position-absolute ">
                        <div class="text-content p-2  bg-burgendy rounder_rightBottom__border text-white "><b class="p-4 fs-6">  {{news ?.createdDate| date: 'dd' }} </b><br> <b class="p-3 fs-6">  {{news?.createdDate|  date: 'MMM'  }}</b></div>
                      </div> -->
                      <div class="image-container w-100 overflow-hidden">
                        <img src="{{news?.image}}" alt="blog image" class="img-fluid bg-black img-fixed-size">
                      </div>
                </div>
                </div>
                <div>
                  <h5 class="fw-semibold pt-2 pl-3 custom_width__ForTextWrap text-truncate">{{news?.heading}}</h5>
                </div>
                <div>
                  <p-editor class="pEditor__Readonly  text-wrap w-100 " [(ngModel)]="news.description" [readonly]="true" maxlength="100"></p-editor>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-10 offset-md-1 pt-5 text-center">
          <p-button label="View All News" icon="pi pi-arrow-right" iconPos="right" class="maroon_btn"
            (click)="navigateToNewsScreen()"></p-button>
        </div>
      </div>
    </div>
    <div class="col-12 text-center" *ngIf="newsLoader">
      <p-progressSpinner
      styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
      animationDuration=".5s"></p-progressSpinner>
    </div>
  </div>
</div>
<p-confirmDialog key="sessionExpiry" #cd [style]="{width: '50vw'}" class="session_tiomeout__popup">
  <ng-template pTemplate="footer">
    <a (click)="cd.reject()"><span
      class="text-dark d-inline-block p-3 cursor-pointer">Cancel</span></a>
      <p-button type="button" class="primeNgButton"   label="LOGIN" (click)="cd.accept()"></p-button>
  </ng-template>
  </p-confirmDialog>